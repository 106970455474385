import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import silly from "./silly.png"

const AboutPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="About Me" />
            {/* <img src={silly} /> */}
            <p>Every few years I lose my identity and have to re-discover <i>who am I now?</i> I'm currently having one of those moments.</p>
            <p></p>
            <p>But there's some underlying essence of Priya that's always true, and Visa captured it here:</p>
            <blockquote class="twitter-tweet"><p lang="und" dir="ltr"> <a href="https://t.co/aDQ2EyBgO0">pic.twitter.com/aDQ2EyBgO0</a></p>&mdash; Visa is fixing the typos in his ebook (@visakanv) <a href="https://twitter.com/visakanv/status/1134464769723838467?ref_src=twsrc%5Etfw">May 31, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <br />
            {/* <p>i also have a lot of oPiNiOnS, like these:</p>
            <ul>
                <li>Wow, humans
                <ul>
                        <li>Humans made <a href="https://twitter.com/Prigoose/status/1140284067054624778?s=20">literally everything around us</a>. We’re pretty great!</li>
                    </ul>
                </li>
                <li><a href="http://priyaghose.io/2020-03-16-honesty-is-kindness-and-white-lies-matter/">Truth is kindness</a>
                    <ul>
                        <li>I strive to be honest. I fail frequently, but I am more direct than most.</li>
                    </ul>
                </li>
                <li>Emotions are adaptive. We evolved to have moods because they give us important data.
                    <ul>
                        <li>For example, sadness means one of your needs isn’t being met (sleep? food? companionship? meaning??)
Emotional pain contains valuable lessons about who you are and what you want. Distracting yourself from pain just <a href="https://twitter.com/Prigoose/status/1129290812473298944?s=20">prolongs the pain</a>; so I advise diving in!</li>
                    </ul>
                </li>
                <li>Twitter is good for you
                    <ul>
                        <li>Twitter is a learning tool. Knowing that I am sharing to a public audience forces me to pause and clarify my thoughts. And I often gain further clarity from conversations in the replies.
👉🏽 <a href="https://twitter.com/Prigoose/status/1129991351523659777?s=20">My thread on how to use Twitter well</a>.</li>
                    </ul>
                </li>
            </ul> */}
        </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
